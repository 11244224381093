<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <c-table 
          :url="'UserWebService/get_roles'" 
          :fields="columns" 
          :export="false"
          :edit_has="true"
          :edit_route="'system.role.edit'"
          :edit_params="{id : 0}"
          hover bordered caption="" 
        ></c-table>  
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Role',
  data: function () {
    return {
      columns: [
        { label: '№', key: 'index', class: 'text-center' },
        { label: 'Код', key: 'code', sortable: true, sort_key: "name" },
        { label: 'Нэр', key: 'name', sortable: true, sort_key: "display_name" },
        { label: 'Идэвхтэй эсэх', key: 'isActive', sortable: true, sort_key: "is_active", class: 'text-center' },
        { label: 'Үйлдэл', key: 'actions', buttons: [], class: 'text-center' },
      ]
    }
  }
}
</script>
